<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Nombre *" />
      <BorderInput
        v-model="personnel.data.name"
        label="Nombre *"
        @keyup.enter="onSave"
      />
      <FormError :show="personnel.rules.name" message="Ingrese un nombre" />
      <BasicLabel label="CI *" />
      <BorderInput
        v-model="personnel.data.identity"
        label="CI *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="personnel.rules.identity"
        message="Ingrese un número de documento válido sin espacios, puntos y guiones"
      />
      <BasicLabel label="Correo *" />
      <BorderInput
        v-model="personnel.data.email"
        label="Correo *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="personnel.rules.email"
        message="Ingrese un email válido"
      />
      <BasicLabel label="Teléfono *" />
      <div class="pd-flex">
        <BorderInputPrefix label="+598" />
        <BorderInputPhone
          v-model="personnel.data.phone"
          label="Teléfono *"
          @keyup.enter="onSave"
        />
      </div>
      <FormError
        :show="personnel.rules.phone"
        message="Ingrese un teléfono válido"
      />
      <BasicLabel label="Cargo *" />
      <BorderSelect
        v-model="personnel.data.personnelTypeId"
        label="Cargo *"
        :options="store.state.general.personnelType"
      />
      <FormError
        :show="personnel.rules.personnelTypeId"
        message="Seleccione un cargo"
      />
    </div>
  </div>
  <MobileTable
    v-model:selection="store.state.personnel.selectedWorkday"
    label="Definición de Días y Horarios Disponibles *"
    :columns="personnel.columns"
    :data="store.state.personnel.workday"
  />
  <DesktopTable
    v-model:selection="store.state.personnel.selectedWorkday"
    label="Jornada Laboral *"
    :columns="personnel.columns"
    :data="store.state.personnel.workday"
  />
  <Actions :items="personnel.actions" />
  <div class="container">
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
  <BasicModal v-model:visible="personnel.showDialog">
    <template v-slot:dialog>
      <BasicTitle title="Jornada Laboral" />
      <BasicSubtitle
        subtitle="Los campos señalados con (*) son obligatorios."
      />

      <div class="container-form">
        <div class="checkbox-container">
          <div v-for="item in personnel.days" :key="item">
            <Checkbox
              :id="item"
              :value="item"
              v-model="personnel.workday.data.days"
            />
            <label :for="item">{{ item }}</label>
          </div>
        </div>
        <FormError
          :show="personnel.workday.rules.days"
          message="Seleccione un día como mínimo"
        />
        <BasicLabel label="Hora Inicio *" />
        <BorderTime
          v-model="personnel.workday.data.from"
          label="Hora Inicio *"
        />
        <FormError
          :show="personnel.workday.rules.from"
          message="Seleccione una hora de inicio"
        />
        <BasicLabel label="Hora Fin *" />
        <BorderTime v-model="personnel.workday.data.to" label="Hora Fin *" />
        <FormError
          :show="personnel.workday.rules.to"
          message="Seleccione una hora de fin válida superior a la hora de inicio"
        />
        <PrimaryButton label="Guardar" :click="onSaveWorkday" />
      </div>
    </template>
  </BasicModal>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode, actions, validation, dates } from "../../constants";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import BorderTime from "../../widgets/calendar/BorderTime.vue";

export default {
  components: {
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    OutlinedTextArea,
    FormError,
    PrimaryButton,
    MobileTable,
    DesktopTable,
    Actions,
    BasicModal,
    BorderTime,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const personnel = reactive({
      showDialog: false,
      data: {
        id: "",
        buildingId: store.state.general.selectedBuilding,
        name: "",
        identity: "",
        email: "",
        phone: "",
        personnelTypeId: "",
      },
      rules: {
        name: false,
        identity: false,
        email: false,
        phone: false,
        personnelTypeId: false,
      },
      days: ["L", "M", "X", "J", "V", "S", "D"],
      workday: {
        data: {
          days: [],
          from: "",
          to: "",
        },
        rules: {
          days: false,
          from: false,
          to: false,
        },
      },
      columns: [
        {
          field: "days",
          header: "Días",
          sort: true,
          center: true,
        },
        {
          field: "from",
          header: "Inicio",
          sort: true,
          time: true,
          center: true,
        },
        {
          field: "to",
          header: "Fin",
          sort: true,
          time: true,
          center: true,
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: () => (personnel.showDialog = true),
          hidden: true,
        },
        {
          name: "Eliminar",
          action: async () => {
            const selected = store.state.personnel.selectedWorkday.map(
              (item) => item.id
            );
            const workday = store.state.personnel.workday.filter(
              (item) => !selected.includes(item.id)
            );
            await store.commit("setSelectedWorkday", []);
            await store.commit("setWorkday", workday);
          },
          hidden: true,
        },
      ],
    });

    const validate = () => {
      let valid = true;

      if (!personnel.data.name) {
        personnel.rules.name = true;
        valid = false;
      }
      if (
        !personnel.data.identity ||
        !validation.validateCI(personnel.data.identity)
      ) {
        personnel.rules.identity = true;
        valid = false;
      }
      if (
        !personnel.data.email ||
        !validation.emailRegex.test(personnel.data.email)
      ) {
        personnel.rules.email = true;
        valid = false;
      }
      if (
        !personnel.data.phone ||
        !validation.phoneRegex.test(personnel.data.phone)
      ) {
        personnel.rules.phone = true;
        valid = false;
      }
      if (!personnel.data.personnelTypeId) {
        personnel.rules.personnelTypeId = true;
        valid = false;
      }

      return valid;
    };

    const validateWorkday = () => {
      let valid = true;

      if (!personnel.workday.data.days.length) {
        personnel.workday.rules.days = true;
        valid = false;
      }
      if (!personnel.workday.data.from) {
        personnel.workday.rules.from = true;
        valid = false;
      }
      if (
        !personnel.workday.data.to ||
        personnel.workday.data.to === "24:00" ||
        personnel.workday.data.to - personnel.workday.data.from < 0
      ) {
        personnel.workday.rules.to = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;
      if (!store.state.personnel.workday.length)
        return store.state.toast.add({
          severity: "error",
          summary: "",
          detail: "Debe agregar al menos una jornada laboral",
          life: 5000,
        });

      store.commit("setLoading", true);

      const request = {
        buildingId: store.state.general.selectedBuilding,
        id: personnel.data.id,
        name: personnel.data.name,
        identity: personnel.data.identity,
        email: personnel.data.email,
        phone: `+598 ${personnel.data.phone}`,
        personnelTypeId: personnel.data.personnelTypeId,
        workday: store.state.personnel.workday,
      };

      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(
          actions.personnelActions.update,
          request
        );
      } else {
        response = await store.dispatch(
          actions.personnelActions.create,
          request
        );
      }
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedPersonnelList", []);
        store.commit("setWorkday", []);
        router.push("/personal/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    const onSaveWorkday = async () => {
      if (!validateWorkday()) return;

      const workday = store.state.personnel.workday;
      const obj = {
        id: validation.getRndInteger(1, 1000),
        days: personnel.workday.data.days.join("/"),
        from: new Date(personnel.workday.data.from).getTime(),
        to: new Date(personnel.workday.data.to).getTime(),
      };

      workday.push(obj);

      await store.commit("setWorkday", workday);
      personnel.showDialog = false;
      personnel.workday.data.days = [];
      personnel.workday.data.from = "";
      personnel.workday.data.to = "";
    };

    onBeforeMount(async () => {
      if (!store.state.openMode) router.push("/personal/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      store.commit("setLoading", true);
      await store.dispatch(actions.generalActions.personnelType);
      if (store.state.openMode === openMode.MODIFY) {
        const selected = store.state.personnel.selectedPersonnelList[0];
        personnel.data.id = selected.id;
        personnel.data.name = selected.name;
        personnel.data.identity = selected.identity;
        personnel.data.email = selected.email;
        personnel.data.phone = selected.phone
          ? selected.phone.split(" ")[1]
          : "";
        personnel.data.personnelTypeId = selected.personnelTypeId;
        await store.commit("setWorkday", selected.workday);
      } else {
        await store.commit("setWorkday", []);
      }
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (personnel.data.name) personnel.rules.name = false;
      if (
        personnel.data.identity &&
        validation.validateCI(personnel.data.identity)
      )
        personnel.rules.identity = false;
      if (
        personnel.data.email &&
        validation.emailRegex.test(personnel.data.email)
      )
        personnel.rules.email = false;
      if (
        personnel.data.phone &&
        validation.phoneRegex.test(personnel.data.phone)
      )
        personnel.rules.phone = false;
      if (personnel.data.personnelTypeId)
        personnel.rules.personnelTypeId = false;

      if (personnel.workday.data.days.length)
        personnel.workday.rules.days = false;
      if (personnel.workday.data.from) personnel.workday.rules.from = false;
      if (personnel.workday.data.to) personnel.workday.rules.to = false;
    });

    return {
      store,
      personnel,
      onSave,
      onSaveWorkday,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
  gap: 0.8rem;
  font-family: "klavika";
  color: var(--primary);
}
.checkbox-container > div > label {
  margin-left: 0.5rem;
}
</style>
